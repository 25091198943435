<template>
    <section id="work_top">
        <div class="inner w1200 txt-center">
            <h2 class="txt-bold">
                How Incredible<br>
                way to make a difference
            </h2>
            <p>
                對客戶需求的傾聽，再經由雙方的溝通討論，融合出最佳成果。<br>
                讓我們成為你最佳的網路事業開發夥伴 —— <br><br>
                「怎漾，懂你的不一樣。」
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'workTopArea'
}
</script>

<style scoped lang="scss">
    #work_top{
        width: 100%;
        padding: calc(10% + 60px) 0 10%;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        .inner{
            h2{
                margin-bottom: 50px;
                text-transform: uppercase;
            }
        }
    }
    @media screen and (max-width: 768px){
        #work_top{
            padding: 130px 0 80px;
            .inner{
                h2{
                    margin-bottom: 50px;
                }
            }
        }
    }
</style>