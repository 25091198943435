<template>
    <section id="work_list">
        <ul class="work_tabs w1000">
            <li class="tab txt-bold">
                <input
                    type="radio"
                    name="service_types"
                    value="all"
                    id="all"
                    v-model="classificationFliter"
                    @click="
                        $router.push(`/works`);
                    ">
                <label for="all">
                    <p class="en">
                        ALL
                    </p>
                    <p class="zh">
                        全部
                    </p>
                </label>
            </li>
            <li class="tab txt-bold" v-for="(item, index) in allClassification" :class="classByClassification(item.en)">
                <input
                    type="radio"
                    name="service_types"
                    :value="item.en"
                    :id="item.en"
                    v-model="classificationFliter"
                    @click="
                        $router.push(`/works/${item.en}`);
                    ">
                <label :for="item.en">
                    <p class="en">
                        {{item.en}}
                    </p>
                    <p class="zh">
                        {{item.zh}}
                    </p>
                </label>
            </li>
        </ul>
        <div class="itembox w1400">
            <article @click="toWorkInfo(item.id, item.classification, item.name.en)" v-for="(item, index) in showItem" v-if="item.show==0"
            class="item" :class="classByClassification(item.classification)">
                <div class="pic_inner" v-if="item.preview_type == 'picture'">
                    <figure v-bind:style="{backgroundImage: 'url('+ storageApi+item.previewPic+')'}"></figure>
                    <div class="text">
                        <h4 class="txt-bold">
                            <span class="for_show">{{item.name.en}}</span>
                            <span class="for_deco">{{item.name.en}}</span>
                        </h4>
                        
                        <small class="txt-grey">{{item.name.zh}}</small>
                    </div>
                </div>
                <div class="pic_inner" v-if="item.preview_type == 'video'">
                    <figure>
                        <video muted autoplay loop playsinline webkit-playsinline>
                            <source :src="storageApi+item.previewPic">
                        </video>
                    </figure>
                    <div class="text">
                        <h4 class="txt-bold">
                            <span class="for_show">{{item.name.en}}</span>
                            <span class="for_deco">{{item.name.en}}</span>
                        </h4>
                        <small class="txt-grey">{{item.name.zh}}</small>
                    </div>
                </div>
            </article>
        </div>
    </section>
</template>

<script>
export default {
    name: 'workList',
    props: ['classification'],
    data: function(){
        return {
            // storageApi: '',
            storageApi: process.env.VUE_APP_STORAGE_KEY,
            classByClassification: function(Classification){
                if(Classification == 'website'){return 'web'}
                if(Classification == 'digital event'){return 'event'}
                if(Classification == 'graphic design'){return 'design'}
                if(Classification == 'video'){return 'video'}
                if(Classification == 'interior design'){return 'interior'}
            },
            allClassification: [],
            allWork: [],
            classificationFliter: "all",
            showItem: []
        }
    },
    watch: {
        classificationFliter(v) {
            console.log(v)
            this.filterWork(v);
        }
    },
    methods: {
        getAllClassification: function(){
            this.axios({
                method: 'get',
                // baseURL: 'https://work.howdesign.com.tw/_API/howgroup/db2.json',
                baseURL: process.env.VUE_APP_API_KEY,
                url: '/works',
                'Content-Type': 'application/json',
            })
            .then((result) => { 
                result.data.service_types.forEach(element => {
                    this.allClassification.push(element);
                });
            })
            .catch((err) => { console.error(err) }) 
        },
        getAllWork() {
            this.axios({
                method: 'get',
                // baseURL: 'https://work.howdesign.com.tw/_API/howgroup/db2.json',
                baseURL: process.env.VUE_APP_API_URL,
                // baseURL: 'http://localhost:9005/api',
                url: '/works',
                'Content-Type': 'application/json',
            })
            .then((result) => { 
                console.log(result.data)
                result.data.works.forEach(element => {
                    this.allWork.push(element);
                    this.showItem.push(element);
                });

                if (this.classification) {
                    if(
                        this.classification == 'website'
                        || this.classification == 'digital event'
                        || this.classification == 'graphic design'
                        || this.classification == 'video'
                        || this.classification == 'interior design'
                        || this.classification == 'all'
                    ) {
                        this.classificationFliter = this.classification;
                        // this.filterWork(this.classification);
                    }
                }
            })
            .catch((err) => { console.error(err) }) 
        },
        filterWork: function(forFilter){
            this.showItem = [];
            this.allWork.forEach(element => {
                if(element.classification == forFilter){
                    this.showItem.push(element);
                }else if(forFilter == 'all'){
                    this.showItem.push(element);
                }
            });
            for(var i=0; i<document.querySelectorAll('video').length; i++){
                document.querySelectorAll('video')[i].load();
            }
        },
        toWorkInfo: function(id, classification, workName){
            this.$router.push({path: `/work_info/${id}/${classification}/${workName}`})
        }
    },
    mounted: function(){
        // console.log(this.classification);
        this.getAllClassification();
        this.getAllWork();
    }
}
</script>

<style scoped lang="scss">
    #work_list{
        .work_tabs{
            margin-bottom: 5%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tab{
                padding: 10px;
                text-transform: uppercase;
                cursor: pointer;
                user-select: none;
                label{
                    position: relative;
                    transition: .3s;
                    text-align: center;
                    position: relative;
                    overflow: hidden;
                    .en{
                        transition: .3s;
                    }
                    .zh{ 
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateY(30px) translateX(-50%);
                        opacity: 0;
                        white-space: nowrap;
                        transition: .3s;
                    }
                    &:hover{
                        .en{
                            transform: translateY(-30px);
                            opacity: 0;
                        }
                        .zh{
                            transform: translateY(0) translateX(-50%);
                            opacity: 1;
                        }
                    }
                    &::after{
                        content: '';
                        margin-top: 5px;
                        width: 100%;
                        height: 3px;
                        display: block;
                        background-color: transparent;
                        transition: .3s;
                    }
                }
                input:checked + label::after{
                    background-color: #000;
                }
                &.web{
                    input:checked + label::after{
                        background-color: $color-yellow;
                    }
                }
                &.event{
                    input:checked + label::after{
                        background-color: $color-red;
                    }
                }
                &.design{
                    input:checked + label::after{
                        background-color: $color-green;
                    }
                }
                &.video{
                    input:checked + label::after{
                        background-color: $color-blue;
                    }
                }
                &.interior{
                    input:checked + label::after{
                        background-color: $color-purple;
                    }
                }
            }
        }
        .itembox{
            padding-bottom: 5%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            .item{
                width: 33.3333%;
                margin-bottom: 5%;
                cursor: pointer;
                figure{
                    width: 100%;
                    padding-bottom: 60%;
                    background-size: cover;
                    background-position: center;
                    position: relative;
                    video{
                        display: block;
                        position: absolute;
                        background-color: #000;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
                .text{
                    padding: 25px 15px 10px 0;
                    text-transform: uppercase;
                    position: relative;
                    &::before{
                        content: '';
                        margin-bottom: 20px;
                        display: block;
                        position: relative;
                        width: 50px;
                        height: 5px;
                        background-color: $color-yellow;
                    }
                    h4{
                        position: relative;
                        .for_show{
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            display: block;
                            &::before{
                                content: '';
                                width: 103%;
                                height: 100%;
                                background-color: #fff;
                                position: absolute;
                                bottom: -10px;
                                left: -10px;
                                transform-origin: right;
                                transform: scaleX(1);
                                transition: .5s;
                                z-index: -1;
                            }
                        }
                        .for_deco{
                            display: inline;
                            background: linear-gradient(180deg, transparent 50%, $color-yellow 50%);
                            color: transparent;
                            position: relative;
                            top: 0;
                            left: 0;
                            bottom: -10px;
                            left: -10px;
                            opacity: .6;
                            transform: scaleX(0);
                            transform-origin: left;
                            transition: .3s;
                        }
                    }
                    small{
                        margin-top: 10px;
                        display: block;
                    }
                }
                &:hover{
                    .text{
                        h4{
                            .for_show{
                                &::before{
                                    transform: scaleX(0);
                                }
                            }
                        }
                    }
                }
                &.web{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-yellow;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-yellow 50%);
                            }
                        }
                    }
                }
                &.event{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-red;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-red 50%);
                            }
                        }
                    }
                }
                &.design{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-green;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-green 50%);
                            }
                        }
                    }
                }
                &.video{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-blue;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-blue 50%);
                            }
                        }
                    }
                }
                &.interior{
                    .text{
                        &::before{
                            content: '';
                            background-color: $color-purple;
                        }
                        h4{
                            .for_deco{
                                background: linear-gradient(180deg, transparent 50%, $color-purple 50%);
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px){
        #work_list{
            .work_tabs{
                flex-wrap: wrap;
                text-align: center;
                justify-content: flex-start;
                .tab{
                    width: 33.3333%;
                }
            }
            .itembox{
                .item{
                    width: 50%;
                }
            }
        }
    }
    @media screen and (max-width: 768px){
        #work_list{
            .work_tabs{
                .tab{
                    width: 50%;
                }
            }
        }
    }
    @media screen and (max-width: 425px){
        #work_list{
            .work_tabs{
                .tab{
                    width: 100%;
                }
            }
            .itembox{
                .item{
                    width: 100%;
                }
            }
        }
    }
</style>