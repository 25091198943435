<template>
    <div class="page">
        <publicHeader/>
        <workTopArea/>
        <workList
            :classification="$attrs.classification || null"/>
    </div>
</template>

<script>
import publicHeader from '@/components/public/Header.vue'
import workTopArea from '@/components/page/work/WorkTopArea.vue'
import workList from '@/components/page/work/WorkList.vue'
export default {
    name: 'Works',
    components: {
        publicHeader,
        workTopArea,
        workList
    }
}
</script>

<style scoped lang="scss">
    .page{
        position: relative;
    }
</style>